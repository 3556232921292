// @flow
import React from 'react'
import { graphql } from 'gatsby'
import { useIntl } from 'react-intl'
import { loadStripe } from '@stripe/stripe-js'

import { withIntl } from 'src/intl'
import { Layout, NavbarTutoring } from 'src/common/components'
import { isOdd } from 'src/common/utils'

import HeadTags from 'src/components/HeadTags'
import TutoringBenefit from 'src/components/ContentRow/TutoringBenefit'
import Testimonials from 'src/components/ContentRow/Testimonials'
import GetInTouchByEmail from 'src/components/ContentRow/GetInTouchByEmail'

const STRIPE_PUBLIC_KEY =
  process.env.BRANCH === 'master'
    ? process.env.GATSBY_STRIPE_PUBLIC_KEY
    : process.env.GATSBY_STRIPE_TEST_PUBLIC_KEY

// TODO - interim as can't get the above to work
const stripePromise = loadStripe('pk_live_11CE1Pa7Fy7Fmg0fNv98clBm')

type PageContext = {
  locale: string,
  pagePath: string,
}

type Props = {|
  data: Object,
  pageContext: PageContext,
|}

function TutoringPage(props: Props) {
  const { data, pageContext } = props
  const { locale } = useIntl()
  const {
    meta,
    stripePlanId,
    benefits,
    testimonials,
    getInTouch,
  } = data.content.frontmatter

  const { heading, desc, buttonText, email } = getInTouch

  const handleClick = async event => {
    const stripe = await stripePromise
    const { error } = await stripe.redirectToCheckout({
      items: [{ plan: stripePlanId, quantity: 1 }],
      successUrl: `${props.location.origin}/${locale}/tutoring-payment-success`,
      cancelUrl: props.location.href,
    })
  }

  return (
    <>
      <HeadTags pageContext={pageContext} {...meta} />
      <Layout>
        {{
          navbar: <NavbarTutoring />,
          body: (
            <>
              {benefits.map((benefit, i) => (
                <TutoringBenefit
                  content={{
                    heading: benefit.title,
                    description: benefit.desc,
                    button: {
                      text: benefit.button.buttonText,
                      onClick: benefit.button.isExternal
                        ? null
                        : () => handleClick(),
                      isExternal: benefit.button.isExternal,
                      linkExternal: benefit.button.linkExternal,
                    },
                    imageLink: benefit.imageUrl,
                  }}
                  firstRow={i === 0}
                  textOnRight={isOdd(i)}
                  key={`${i} - ${benefit.heading}`}
                />
              ))}
              <Testimonials content={testimonials} />
              <GetInTouchByEmail
                content={{
                  heading,
                  description: desc,
                  button: {
                    text: buttonText,
                    external: true,
                    linkExternal: `mailto:${email}`,
                  },
                }}
              />
            </>
          ),
        }}
      </Layout>
    </>
  )
}

export default withIntl(TutoringPage)

export const TutoringPageQuery = graphql`
  query TutoringPageQuery($id: String!) {
    content: markdownRemark(id: { eq: $id }) {
      frontmatter {
        ...MetaFragment
        stripePlanId
        getInTouch {
          heading
          desc
          buttonText
          email
        }
        benefits {
          title
          desc
          imageUrl
          button {
            linkExternal
            buttonText
            isExternal
          }
        }
        testimonials {
          heading
          list {
            image
            testimonial
            author
            position
          }
        }
      }
    }
  }
`
