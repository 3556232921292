export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

//TODO: do this using react-intl library
export const dateFormatter = date => {
  let unformattedDate = new Date(date)
  let formattedDate = `${
    months[unformattedDate.getMonth()]
  } ${unformattedDate.getDate()}, ${unformattedDate.getFullYear()}`
  return formattedDate
}

export const isOdd = value => {
  if (value % 2 == 0) return false
  else return true
}

export const alphabeticallySortedSubSectionsForASubject = (
  arrayOfSubSections,
  subject
) => {
  const sortedAlphabteticalSubSections = arrayOfSubSections.sort((a, b) =>
    a.title.localeCompare(b.title)
  )
  return sortedAlphabteticalSubSections.filter(
    subSection => subSection.subject === subject
  )
}

export const generateArrayOfSubSections = (
  posts: Array<Object>,
  filterByIsDiscoverable
) => {
  let allPosts = posts

  if (filterByIsDiscoverable) {
    allPosts = posts.filter(
      post => post.node.frontmatter.isDiscoverable === true
    )
  }

  return allPosts.map((post, i) => {
    const {
      fields: { slug },
      frontmatter: { title, subject },
    } = post.node

    return { title, subject, slug }
  })
}

export const getAlphabeticalArrayOfSubjects = (
  arrayOfSubSections: Array<Object>
) => {
  const arrayOfSubjects = []

  arrayOfSubSections.forEach(subSection => {
    const { subject } = subSection
    if (arrayOfSubjects.indexOf(subject) === -1) {
      arrayOfSubjects.push(subject)
    }
  })

  return arrayOfSubjects.sort()
}

export const createSubjectsAndSubSectionsArray = (
  posts: Array<Object>,
  filterByIsDiscoverable = false
) => {
  const arrayOfSubSections = generateArrayOfSubSections(
    posts,
    filterByIsDiscoverable
  )
  const alphabeticalArrayOfSubjects = getAlphabeticalArrayOfSubjects(
    arrayOfSubSections
  )

  const subjectsAndSubSectionsArray = alphabeticalArrayOfSubjects.map(
    subject => {
      const SubSections = alphabeticallySortedSubSectionsForASubject(
        arrayOfSubSections,
        subject
      )

      return { subject, SubSections }
    }
  )

  return subjectsAndSubSectionsArray
}
