// @flow
import React from 'react'

import { ButtonLink, type ButtonProps } from 'src/common/components/Button'
import PageRow from 'src/common/components/PageRow'
import PostContent from 'src/common/components/PostContent'

import styles from './GetInTouchByEmail.module.scss'

type ContentProps = {|
  heading: string,
  description: string,
  button: ButtonProps,
|}

type Props = {|
  content: ContentProps,
|}

const GetInTouchByEmail = ({
  content: { heading, description, button },
}: Props) => {
  return (
    <PageRow className={styles.lightBlue}>
      <div className={styles.container}>
        <h2 className={styles.header}>{heading}</h2>
        <PostContent className={styles.text} content={description} />
        <ButtonLink color={'blue'} {...button} />
      </div>
    </PageRow>
  )
}

export default GetInTouchByEmail
