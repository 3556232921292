// @flow
import React from 'react'
import classNames from 'classnames'

import Button, {
  ButtonLink,
  type ButtonProps,
} from 'src/common/components/Button'
import PageRow from 'src/common/components/PageRow'
import PostContent from 'src/common/components/PostContent'
import Image from 'src/common/components/Image'

import styles from './TutoringBenefit.module.scss'

type ContentProps = {|
  heading: string,
  description: string,
  button: ButtonProps,
  imageLink: string,
|}

type Props = {|
  content: ContentProps,
  firstRow: Boolean,
  textOnRight: string,
|}

const TutoringBenefit = ({
  content: { heading, description, button, imageLink },
  className,
  firstRow,
  textOnRight,
}: Props) => {
  return (
    <PageRow
      firstRow={firstRow}
      className={classNames(className, {
        [styles.malibu]: !textOnRight,
      })}
    >
      <div
        className={classNames(styles.container, {
          [styles.left]: !textOnRight,
        })}
      >
        <div className={styles.image}>
          <Image src={imageLink} alt={heading} widths={{ desktop: 600 }} />
        </div>
        <div
          className={classNames(styles.content, {
            [styles.textOnRight]: textOnRight,
          })}
        >
          <div
            className={classNames(styles.header, {
              [styles.whiteText]: !textOnRight,
            })}
          >
            {heading}
          </div>
          <PostContent
            className={classNames(styles.text, {
              [styles.whiteText]: !textOnRight,
            })}
            content={description}
          />
          {button.isExternal ? (
            <ButtonLink
              color={textOnRight ? 'blue' : 'white'}
              {...button}
              external
              openInNewTab
            />
          ) : (
            <Button color={textOnRight ? 'blue' : 'white'} {...button} />
          )}
        </div>
      </div>
    </PageRow>
  )
}

export default TutoringBenefit
